<template>
  <v-container fluid class="content-wrapper">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body ml-1">
          <!-- Products sell and New Orders -->
          <div class="row match-height">
            <div class="col-12">
              <div class="d-flex flex-row justify-start align-center mb-5">
                <h4 class="fw-600 mb-0">
                  {{ $t("SigninPromotion.edit_campaign") }}
                </h4>
                <v-btn
                  icon
                  class="ml-3"
                  @click="initAll()"
                  :class="loadingData && 'animate__spining'"
                  :disabled="loadingData"
                >
                  <v-icon class="">mdi-reload</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div v-if="loadingData" class="d-flex flex-column" style="width: 100%">
            <v-skeleton-loader
              type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
            ></v-skeleton-loader>
          </div>

          <div v-if="!loadingData" class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
              <div class="row p-1 border-dark">
                <div class="col-12">
                  <h5 class="fw-600 mb-2">
                    <i class="ft-tag font-large-1 pr-1"></i
                    >{{ $t("SigninPromotion.campaign_name") }}
                  </h5>
                </div>
                <div class="col-12">
                  <div class="form-group validate">
                    <div class="controls">
                      <input
                        type="text"
                        id="name"
                        class="form-control"
                        :placeholder="`${$t('SigninPromotion.campaign_name')}`"
                        name=""
                        v-model="campaignName"
                        @focus="getfocus('name')"
                        @change="getfocus('name')"
                        @mouseleave="mouseOver('name')"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  <label for="textarea2">{{
                    $t("SigninPromotion.campaign_detail")
                  }}</label>
                  <textarea
                    type="text"
                    id="description"
                    rows="3"
                    class="form-control"
                    :placeholder="`${$t('SigninPromotion.campaign_detail')}`"
                    name=""
                    v-model="campaignDescription"
                    @focus="getfocus('description')"
                    @change="getfocus('description')"
                    @mouseleave="mouseOver('description')"
                  />
                </div>

                <div class="col-12 mt-1">
                  <div class="row">
                    <div class="col-md-4 col-sm-12 col-12">
                      <div class="form-group">
                        <label for="text1">{{
                          $t("SigninPromotion.campaign_discount_percent")
                        }}</label>
                        <input
                          type="number"
                          min="0"
                          @keypress="isNumber($event)"
                          id="percent"
                          class="form-control"
                          placeholder="10 %"
                          name=""
                          v-model="campaignPercent"
                          @focus="getfocus('percent')"
                          @change="getfocus('percent')"
                          @mouseleave="mouseOver('percent')"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12">
                      <div class="form-group">
                        <label for="text2">{{
                          $t("SigninPromotion.campaign_discount_code")
                        }}</label>
                        <input
                          type="text"
                          id="code"
                          class="form-control"
                          placeholder="123456"
                          name=""
                          v-model="campaignCode"
                          @focus="getfocus('code')"
                          @change="getfocus('code')"
                          @mouseleave="mouseOver('code')"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-12">
                      <div class="form-group">
                        <label for="text3">{{
                          $t("SigninPromotion.campaign_limit_privilege")
                        }}</label>
                        <input
                          type="number"
                          min="0"
                          @keypress="isNumber($event)"
                          id="limit"
                          class="form-control"
                          placeholder="30"
                          name=""
                          v-model="campaignLimit"
                          @focus="getfocus('limit')"
                          @change="getfocus('limit')"
                          @mouseleave="mouseOver('limit')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-promotion-2 p-1 border-dark">
                <div class="col-12">
                  <h5 class="fw-600 mb-2">
                    <i class="ft-tag font-large-1 pr-1"></i
                    >{{ $t("SigninPromotion.campaign_room_type") }}
                  </h5>
                </div>

                <div class="col-12 mt-1 scope-open">
                  <v-radio-group row v-model="campainAllRooms">
                    <v-radio
                      :label="`${$t('SigninPromotion.campaign_all_room')}`"
                      :value="true"
                      color="#1e9ff2"
                      @change="checkAllRooms()"
                    ></v-radio>
                    <v-radio
                      :label="`${$t('SigninPromotion.campaign_some_room')}`"
                      :value="false"
                      color="#1e9ff2"
                    ></v-radio>
                  </v-radio-group>
                  <v-row v-if="campainAllRooms == false">
                    <v-col cols="12" sm="12" md="4" lg="3"></v-col>
                    <v-col cols="12" sm="12" md="8" lg="9">
                      <div v-for="item in campaignRooms" :key="item.value">
                        <div class="custom-control custom-checkbox">
                          <input
                            :id="`${item.value}`"
                            name="rooms"
                            type="checkbox"
                            class="custom-control-input"
                            :value="`${item.value}`"
                            :checked="item.check"
                            @change="checkSomeRooms(item)"
                          />
                          <label class="custom-control-label" :for="`${item.value}`">{{
                            item.name
                          }}</label>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div class="row mt-promotion-2 p-1 border-dark">
                <div class="col-12">
                  <h5 class="fw-600 mb-2">
                    <i class="ft-calendar font-large-1 pr-1"></i
                    >{{ $t("SigninPromotion.campaign_promotion_activated") }}
                  </h5>
                </div>

                <div class="col-12 mt-1 scope-open">
                  <div class="row">
                    <div
                      class="col-xl-3 col-lg-4 col-md-5 col-sm-4 col-12 text-right align-self-center"
                    >
                      <label for="date1" class="">{{
                        $t("SigninPromotion.campaign_checkin")
                      }}</label>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12">
                      <v-menu
                        v-model="menuDateStay"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="form-group mb-0" style="width: 13rem">
                            <v-text-field
                              v-model="campaignStayRangeText"
                              label=""
                              class="form-control"
                              readonly
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="campaignStay"
                          range
                          @input="menuDateStay = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div
                      class="col-xl-3 col-lg-4 col-md-5 col-sm-4 col-12 text-right align-self-center"
                    >
                      <label for="date2" class="">{{
                        $t("SigninPromotion.campaign_canuse_voucher")
                      }}</label>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12">
                      <v-menu
                        v-model="menuDateBooking"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="form-group mb-0" style="width: 13rem">
                            <v-text-field
                              v-model="campaignBookingRangeText"
                              label=""
                              class="form-control"
                              readonly
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="campaignBooking"
                          range
                          @input="menuDateBooking = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="mt-1 d-flex flex-wrap">
                    <div
                      class="custom-control custom-checkbox mr-3"
                      v-for="item in itemsDays"
                      :key="item.value"
                    >
                      <input
                        :id="item.value"
                        name="days"
                        type="checkbox"
                        class="custom-control-input"
                        :value="item.value"
                        :checked="item.check"
                        @change="checkListDays(item)"
                      />
                      <label class="custom-control-label" :for="item.value">{{
                        item.name
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loadingData" class="row py-3 mb-5">
          <div class="col-12">
            <v-btn
              outlined
              class="mr-3 btn-warning"
              style="min-height: 2.625rem; min-width: 7.5rem"
              dark
              @click="gobackFunc()"
              >{{ $t("SigninRoomDetails.btn_back") }}</v-btn
            >
            <v-btn
              outlined
              class="btn-primary"
              style="min-height: 2.625rem; min-width: 7.5rem"
              dark
              @click="saveCampaignFunc()"
              >{{ $t("SigninRoomDetails.btn_save") }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninCampaignEditScreen",
  metaInfo: {
    title: "Edit Campaign",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(this.$t("Alert.needtogootherroute"));
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    campaignID: "",
    propertyData: null,
    value_element: "",
    campaign_active: true,
    hCampaignActive: [],
    campaignActiveItems: [],
    campaignDetails: null,
    campaignName: "",
    campaignDescription: "",
    campaignPercent: 0,
    campaignCode: "",
    campaignLimit: 0,
    campainAllRooms: true,
    campaignRooms: [],
    campaignStay: [],
    campaignBooking: [],
    campaignType: "",
    itemSelectRooms: [
      {
        label: "ทุกประเภทห้อง",
        value: true,
      },
      {
        label: "เฉพาะบางประเภท",
        value: false,
      },
    ],
    menuDateStay: false,
    menuDateBooking: false,
    itemsDays: [],
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.campaignID = self.$route.params.campaignId;
    self.initAll();
  },
  computed: {
    campaignStayRangeText() {
      return this.campaignStay.join(` ${this.$t("SigninPromotion.to")} `);
    },
    campaignBookingRangeText() {
      return this.campaignBooking.join(` ${this.$t("SigninPromotion.to")} `);
    },
  },
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.itemsDays = [
        {
          value: "monday",
          name: self.$t("SigninWeekend.monday"),
          check: false,
        },
        {
          value: "tuesday",
          name: self.$t("SigninWeekend.tuesday"),
          check: false,
        },
        {
          value: "wednesday",
          name: self.$t("SigninWeekend.wednesday"),
          check: false,
        },
        {
          value: "thursday",
          name: self.$t("SigninWeekend.thursday"),
          check: false,
        },
        {
          value: "friday",
          name: self.$t("SigninWeekend.friday"),
          check: false,
        },
        {
          value: "saturday",
          name: self.$t("SigninWeekend.saturday"),
          check: false,
        },
        {
          value: "sunday",
          name: self.$t("SigninWeekend.sunday"),
          check: false,
        },
      ];

      self.initPropertyData();
      setTimeout(() => {
        self.loadingData = false;
      }, 1500);
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempRooms = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log(temp);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        temp?.campaign?.map((el) => {
          if (self.campaignID == el?._id) self.campaignDetails = el;
        });
        self.campaignName = self.campaignDetails.campaign_name;
        self.campaignDescription = self.campaignDetails.campaign_detail;
        self.campaignPercent = self.campaignDetails.discount_percent;
        self.campaignCode = self.campaignDetails.campaign_code;
        self.campaignLimit = self.campaignDetails.limit_number;
        temp?.rooms?.map((el) => {
          const nameRoom = self?.campaignDetails?.rooms?.find((p) => p == el?._id);
          if (nameRoom)
            tempRooms.push({
              name: el?.room_name?.value,
              value: el?._id,
              check: true,
            });
          else
            tempRooms.push({
              name: el?.room_name?.value,
              value: el?._id,
              check: false,
            });
        });
        self.campaignRooms = tempRooms;
        if (temp?.rooms?.length == self?.campaignDetails?.rooms?.length)
          self.campainAllRooms = true;
        else self.campainAllRooms = false;
        self.campaignStay = [
          moment(self?.campaignDetails?.stay?.start_date).format("YYYY-MM-DD"),
          moment(self?.campaignDetails?.stay?.end_date).format("YYYY-MM-DD"),
        ];
        self.campaignBooking = [
          moment(self?.campaignDetails?.booking?.start_date).format("YYYY-MM-DD"),
          moment(self?.campaignDetails?.booking?.end_date).format("YYYY-MM-DD"),
        ];
        self.campaignType = self?.campaignDetails?.campaign;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    checkAllRooms() {
      const self = this;
      self.campainAllRooms = !self.campainAllRooms;
      if (self.campainAllRooms) self.campaignRooms.map((el) => (el.check = true));
      else self.campaignRooms.map((el) => (el.check = false));
      // console.log(self.campaignRooms);
    },
    checkSomeRooms(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
      // console.log(self.campaignRooms);
    },
    checkListDays(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    async saveCampaignFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempRooms = [];
      self.campaignRooms?.map((el) => {
        if (el.check) tempRooms.push(el?.value);
      });

      var tempDays = [];
      self?.itemsDays?.map((el) => {
        if (el.check) tempDays.push(el.value);
      });

      const obj = {
        campaign_name: self.campaignName,
        campaign_detail: self.campaignDescription,
        discount_percent: self.campaignPercent,
        campaign_code: self.campaignCode,
        limit_number: self.campaignLimit,
        stay: {
          start_date: self.campaignStay[0],
          end_date: self.campaignStay[1],
          limit_day: tempDays,
        },
        booking: {
          start_date: self.campaignBooking[0],
          end_date: self.campaignBooking[1],
        },
        rooms: tempRooms,
        campaign: self.campaignType,
      };
      // console.log("update: ", obj);

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?campaign_id=${
              self?.campaignID || self?.$route?.params?.campaignId
            }`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res);
          self.gobackFunc();
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.error_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.error_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/promotion.css";

.v-menu__content {
  max-width: 290px !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
